// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { css } from 'glamor'
import { graphql } from 'gatsby'

// import map from "lodash/map";
// import isUndefined from "lodash/isUndefined";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
// import { Link } from 'gatsby'
import Img from 'gatsby-image'

import Row from 'antd/lib/row'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/row/style/css'

import Col from 'antd/lib/col'
import '@bodhi-project/antrd/lib/se-concern-lwes-type/3.10.0/col/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import StandardPage from '../components/StandardPage'

import seoHelper from '../methods/seoHelper'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const pageData = {
  pageTitle: 'Values',
  nakedPageSlug: 'values',
  pageAbstract: 'Content being added at the moment.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    image1: file(relativePath: { eq: "values/value1.jpg" }) {
      ...defaultImage
    }
    image2: file(relativePath: { eq: "values/value2.jpg" }) {
      ...defaultImage
    }
    image3: file(relativePath: { eq: "values/value3.jpg" }) {
      ...defaultImage
    }
    image4: file(relativePath: { eq: "values/value4.jpg" }) {
      ...defaultImage
    }
    image5: file(relativePath: { eq: "values/value5.jpg" }) {
      ...defaultImage
    }
  }
`

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Styles
// ----------------------------------------------------------------------------
const pageStyles = css({
  '& strong': {
    fontWeight: 700,
    color: '#004195',
  },

  '& h1, h2, h3': {
    color: '#004195',
  },

  '& h1': {
    textTransform: 'uppercase',
    fontStyle: 'italic',
    borderTop: '4px solid #004394',
  },

  '& h3': {
    borderTop: '1.5px solid #004394',
  },
})
const pageStyle = pageStyles.toString()

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <StandardPage className={pageStyle} seoData={seoData}>
    <h1 style={{ marginBottom: 0 }}>Core Values</h1>
    <h2 className="mask-h4" style={{ marginBottom: 60 }}>
      Rooted in traditions, looking ever onwards…
    </h2>
    <p>
      At Cambridge International School, the goal is to nurture the dreams of
      tender hearts. Every child is equipped with the confidence to pursue the
      dreams and realize them. In this world of turmoil and unrest they learn to
      pave their own path to success.
    </p>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginBottom: 30 }}>
      <Col sm={24} md={6}>
        <Img fluid={props.data.image1.childImageSharp.fluid} />
      </Col>
      <Col sm={24} md={18}>
        <h2>1. Academic Excellence</h2>
        <p>
          Our challenging, broad-based academic program, which promotes critical
          thinking and creativity, prepares students for success in university
          and in life.
        </p>
      </Col>
    </Row>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginBottom: 30 }}>
      <Col sm={24} md={6}>
        <Img fluid={props.data.image2.childImageSharp.fluid} />
      </Col>
      <Col sm={24} md={18}>
        <h2>2. Continuously Onward</h2>
        <p>
          We foster ethical and spiritual growth in a community founded upon
          integrity, mutual respect, environmental stewardship and service to
          others. Athletics, the arts, outdoor education and community service
          lead to the development of healthy, self-confident individuals by
          encouraging students to participate, to strive for excellence, and to
          develop strong leadership skills.
        </p>
      </Col>
    </Row>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginBottom: 30 }}>
      <Col sm={24} md={6}>
        <Img fluid={props.data.image3.childImageSharp.fluid} />
      </Col>
      <Col sm={24} md={18}>
        <h2>3. Global Citizenship</h2>
        <p>
          Being one of the leading school, recognized by all national and
          international boards with international teaching standards and
          holistic development we prepare our students for global exposure.
        </p>
      </Col>
    </Row>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginBottom: 30 }}>
      <Col sm={24} md={6}>
        <Img fluid={props.data.image4.childImageSharp.fluid} />
      </Col>
      <Col sm={24} md={18}>
        <h2>4. Individual Integrity</h2>
        <p>
          Without individual integrity no leader can succeed. Here, in Learning
          Wings Education System, we believe that at the individual level
          integrity more than ethics, it's more about the character. It is those
          characteristics of an individual that are consistently considerate,
          compassionate, transparent, honest, and ethical. Our students are
          reliable, the one we can count on to do consistently what is right,
          and they are defenders of what is fair, just and acceptable.
        </p>
      </Col>
    </Row>
    <Row gutter={{ xs: 24, sm: 24, md: 24 }} style={{ marginBottom: 30 }}>
      <Col sm={24} md={6}>
        <Img fluid={props.data.image5.childImageSharp.fluid} />
      </Col>
      <Col sm={24} md={18}>
        <h2>5. Committed to serve</h2>
        <p>
          Cambridge International School sets high standards in everything that
          we do, but we also enjoy the challenge. Our highly dedicated staff and
          low teacher/student ratios allow for the development of positive
          relationships between staff and students. This helps to enhance the
          learning environment and allows us to encourage students to try new
          things and stretch their boundaries. Most significantly though, we
          know that it's the people who make a huge difference. Our faculty is
          exceptional, and we are highly committed to ensuring that our teachers
          are up-to date with the latest in educational research, so in turn,
          our students reap the benefits of their on-going learning.
        </p>
      </Col>
    </Row>
  </StandardPage>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
